import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { store } from "@/store/store";

//baseURL: `${process.env.BASE_API_URL}/api`,

const api: AxiosInstance = axios.create({
  baseURL: "https://t4zm28xtsd.execute-api.ap-northeast-1.amazonaws.com/dev2/api",
  // baseURL: "https://9k9xty7972.execute-api.ap-northeast-1.amazonaws.com/prod/api",
  headers: {
    "Content-Type": "application/json",
  },
  xsrfCookieName: "csrftoken",
  xsrfHeaderName: "X-CSRFTOKEN",
});

const http = { api: api };

export default http;

